<template>
  <section id="hero">
    <v-img
      :height="minHeight"
      :src="require('@/assets/home-hero.jpg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <!--<base-heading title="UDACAPI Webinar" />

          <base-body>
            Tuesday, 8 September, at 16.00 Istanbul Time (UTC+3)
          </base-body>
          <base-body>
            Meeting number: 163 474 2759
            Password: Y3fBC4A6MYn
          </base-body>-->

          <base-heading :title="$t('landing-hero.title')" />

          <base-body>
            {{ $t('landing-hero.subtitle') }}
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <!--<base-btn
              href="https://udaconsulting.my.webex.com/udaconsulting.my/j.php?MTID=m26fecc5d23d6126b5bedd5c763599f65"
            >
              Attand the Webinar
            </base-btn>-->
            <base-btn
              href="https://docs.udacapi.com"
            >
              {{ $t('landing-hero.discover-more') }}
            </base-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">{{ $t('common.or') }}</span>

            <base-btn
              :ripple="false"
              class="pa-1"
              height="auto"
              text
              color="blue"
              to="/login"
            >
              {{ $t('landing-hero.get-started') }}
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  import payment from '@/mixins/payment'
  import { useSubscriptionStore } from '@/stores/SubscriptionStore'
  const subscription = useSubscriptionStore()

  export default {
    name: 'SectionHero',

    mixins: [payment],

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },

    created () {
      // get price information from ocpu
      if (!subscription.calls.price) this.getPrices()
      if (!subscription.calls.subscprice) this.getSubcsPrices()
    },
  }
</script>

<style>
.v-application .display-3 {
    font-size: 3rem !important;
}
.v-application .title {
    font-size: 1rem !important;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: 0.1875rem !important;
    font-family: "Work Sans", sans-serif !important;
}
</style>
